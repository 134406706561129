import { AppInfo } from '~/generated/contract';
import { useEnv } from '../../use-env';

export type AppCardProps = { app: AppInfo};
export function AppCard( { app }: AppCardProps ) {
  const env = useEnv();
  return (
    <>
    <div className="component-card flex flex-col">
      <div>
        { app.featuredImage && <img className="component-image flex-grow max-h-28 mx-auto" src={env.appUrl + '/' + app.featuredImage.fullPath} alt={app.excerpt || app.name} />}
      </div>
      <div className="text-center pt-3">
        <p className="component-name text-xs sm:text-sm text-gray-700 w-100">{app.name}</p>
      </div>
    </div>
    </>

  );
}
